'use client';

import styles from './404.module.scss';
import { Button, Result } from 'antd';
import Image from 'next/image';
import errorState from 'assets/images/404.svg';
import { useContext } from 'react';
import { GlobalContext } from 'context';
import { useRouter } from 'next/navigation';
const Error404Comp: React.FC = () => {
  const lang = useContext(GlobalContext).langText.notFound;
  const router = useRouter();
  return <div className={`${styles['tehranto-404']} m-auto`} data-sentry-component="Error404Comp" data-sentry-source-file="404.index.tsx">
            <Result icon={<Image className={styles.image} src={errorState} alt="error image" height={300} />} title={<p>{lang.title}</p>} subTitle={<p>{lang.subTitle}</p>} extra={<Button key={'sada'} onClick={() => router.back()}>
                        {lang.back}
                    </Button>} data-sentry-element="Result" data-sentry-source-file="404.index.tsx" />
        </div>;
};
export default Error404Comp;